// -- basic library --
import React, { useCallback, useState } from 'react';
import InputBox from 'shared/components/atoms/InputBox';
import InputNumberBox from 'shared/components/atoms/InputNumberBox';
import { Table, Tbody, Td, Th, Thead, Tr, CheckBoxArea } from 'shared/components/atoms/PfTable';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import SelectBox from 'shared/components/atoms/SelectBox';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import CheckBox from 'shared/components/molecules/CheckBox';
import ConfirmDialog from 'shared/components/molecules/ConfirmDialog';
import { FlexCenterDiv } from 'shared/components/molecules/ContentsArea';
import { colors } from 'shared/styles/colors';
import styles from 'shared/styles/styles';
import { CsvRow } from 'shared/models/CsvRow';
import { StringBoolean, isStringBoolean } from 'shared/models/StringBoolean';
import { cell_formats } from 'shared/models/CellFormat';
import styled from 'styled-components';
import { streamsIdJsonpathGetAPI } from 'user/api/streams';
import JsonpathInput from 'user/components/molecules/JsonpathInput';
import { UpIcon, DownIcon } from 'shared/components/atoms/Icons';
import Popover from 'shared/components/atoms/Popover';
import { PopoverWholeArea } from 'shared/components/atoms/ContentsArea';
import IconButton from 'shared/components/molecules/IconButton';
import InputComponent from 'shared/components/molecules/InputComponent';
import { useStreamDataReplacementItems } from 'user/hooks/useStreamDataReplacementItems/useStreamDataReplacementItems';
import topmenu_icon_question from 'assets/topmenu_icon_question.png';
import { Spinner } from '@blueprintjs/core';
import { MetricStatisticMethod } from 'shared/models/MetricStatisticMethod';
import { AccumulationType } from 'shared/models/AccumulationType';

// -- type declaration --

interface Params {
  table_area_props?: {
    style?: React.CSSProperties;
  };
  bodies: CsvRow[];
  stream_id?: string;
  showStatistics: boolean;
  setCsvRows: (csv_rows: CsvRow[]) => void;
}

// -- main component --

const CSVTable: React.FC<Params> = (params) => {
  // -- local states --
  const [selected_indexes, setSelectedIndexes] = useState<number[]>([]);
  // n行を追加するのnの部分
  const [add_row_number, setAddRowNumber] = useState<number>(1);
  const [is_open_menu_popover, setIsOpenMenuPopover] = useState<boolean>(false);
  const open = useCallback(() => {
    setIsOpenMenuPopover(true);
  }, []);
  const close = useCallback(() => {
    setIsOpenMenuPopover(false);
  }, []);

  const [replacement_camera_id, setReplacementCameraId] = useState<string>('');
  const [replacement_line_no, setReplacementLineNo] = useState<string>('');
  const { camera_id, line_no } = useStreamDataReplacementItems({ stream_id: params.stream_id ?? '' });

  const handleClickReprace = () => {
    // 置換ボタン押下
    const new_csv_rows = params.bodies.slice();
    new_csv_rows.forEach((csv_row, index) => {
      // selected_indexesに該当するかつcsv_row.json_pathが有効な場合csv_rows.json_pathを置換
      if (selected_indexes.includes(index) && csv_row.json_path) {
        const json_path = csv_row.json_path.split('.');
        if (json_path[1] == ('ts' || 'ts_send')) return;
        let new_json_path = '';
        for (let i = 0; i < json_path.length; i++) {
          // json_path[i]の最初の文字が'c'または'C'であり、replacement_camera_idが存在する場合置換
          if (replacement_camera_id && json_path[i] && (json_path[i][0] === 'c' || json_path[i][0] === 'C')) {
            json_path[i] = replacement_camera_id;
          }
          // json_path[i]が 'LINE' で始まるかどうかを確認し、replacement_line_noが存在する場合置換
          if (replacement_line_no && json_path[i].startsWith('LINE')) {
            const new_line = json_path[i].split('_');
            new_line[0] = replacement_line_no;
            new_json_path += '.' + new_line.join('_');
          } else {
            if (i != 0) {
              new_json_path += '.' + json_path[i];
            } else {
              new_json_path += json_path[i];
            }
          }
        }
        new_csv_rows[index].json_path = new_json_path;
      }
    });
    params.setCsvRows(new_csv_rows);
    // 各項目の初期化
    setSelectedIndexes([]);
    setReplacementCameraId('');
    setReplacementLineNo('');
    AlertDialog.show('置換に成功しました。');
  };

  // -- functions --
  const onChangeAddRowNumber = (value: number) => {
    // const new_value = Number(e.currentTarget.value)
    if (Number.isNaN(value)) {
      setAddRowNumber(value);
    } else {
      setAddRowNumber(Math.min(Math.max(1, value), 500));
    }
  };

  // -- functions --
  // 入力が変更された時の関数
  const handleCsvRowsChangeClick = (value: string, index: number, key: string) => {
    const new_csv_rows = params.bodies.slice();
    if (key === 'header_name') {
      new_csv_rows[index].header_name = value;
    }
    if (key === 'json_path') {
      new_csv_rows[index].json_path = value;
    }
    if (key === 'cell_format_args') {
      new_csv_rows[index].cell_format_args = value;
    }
    if (key === 'cell_format') {
      new_csv_rows[index].cell_format = value;
      if (value !== 'SUBSTRING') {
        new_csv_rows[index].cell_format_args = '';
      }
    }
    if (key === 'statistic_method') {
      new_csv_rows[index].statistic_method = value;
    }
    if (key === 'fill' && isStringBoolean(value)) {
      new_csv_rows[index].fill = value;
    }
    params.setCsvRows(new_csv_rows);
  };
  // テーブルのヘッダーがチェックがどうか判断
  const checkHeaderChecked = () => {
    return params.bodies.length > 0 && params.bodies.length === selected_indexes.length ? true : false;
  };

  const handleHeadCheckClick = () => {
    let arr: number[] = [];
    if (selected_indexes.length < params.bodies.length) {
      arr = new Array(params.bodies.length).fill(null).map((_, i) => i);
    }
    setSelectedIndexes(arr);
  };

  const handleCheckClick = (selected_index: number) => {
    let new_selected_indexes = [...selected_indexes];
    if (selected_indexes.includes(selected_index)) {
      new_selected_indexes = selected_indexes.filter((si) => si !== selected_index);
    } else {
      new_selected_indexes.push(selected_index);
    }
    setSelectedIndexes(new_selected_indexes);
  };

  const handleUpClick = (index: number) => {
    const new_csv_rows = [...params.bodies];
    if (index >= 1) {
      [new_csv_rows[index], new_csv_rows[index - 1]] = [new_csv_rows[index - 1], new_csv_rows[index]];
    }
    params.setCsvRows(new_csv_rows);
  };

  const handleDownClick = (index: number) => {
    const new_csv_rows = [...params.bodies];
    if (index <= new_csv_rows.length - 2) {
      [new_csv_rows[index], new_csv_rows[index + 1]] = [new_csv_rows[index + 1], new_csv_rows[index]];
    }
    params.setCsvRows(new_csv_rows);
  };

  const autoCsvSetting = async (stream_id: string) => {
    let all_jsonpathes: string[] = [];
    const res = await streamsIdJsonpathGetAPI({ stream_id: stream_id });
    if (res.status === 200) {
      all_jsonpathes = res.data.jsonpaths;
    }
    if (all_jsonpathes.length <= 0) {
      AlertDialog.show('有効なjsonpathを取得できませんでした。');
      return;
    }
    const new_csv_rows: CsvRow[] = [];
    for (const jp of all_jsonpathes) {
      new_csv_rows.push({
        header_name: '',
        json_path: jp,
        cell_format: '',
        cell_format_args: '',
        statistic_method: 'Key',
        fill: 'False',
      });
    }
    params.setCsvRows(new_csv_rows);
  };

  const handleAutoSettingCsvClick = () => {
    if (params.stream_id !== undefined) {
      const stream_id = params.stream_id;
      ConfirmDialog.show(
        <div style={{ color: colors.red }}>
          [確認]入力しているCSV定義を上書きして、
          <br />
          自動的にCSV定義を設定します。
          <br />
          入力されたデータを上書きしてよろしいですか?
        </div>,
        () => autoCsvSetting(stream_id),
        () => {},
        undefined,
      );
    } else {
      AlertDialog.show('データが選択されていません。');
    }
  };

  // row_numberの数だけ行を追加する。(MAX500)
  const handleAddClick = (row_number = 1) => {
    const new_csv_rows = [...params.bodies];
    if (new_csv_rows.length >= 500) {
      AlertDialog.show('すでにCSV定義は最大の500行に達しています。');
      return;
    }
    // MINは1
    let add_row_number = Math.max(1, row_number);
    // 500行が最大なので、その差分がMAX
    if (add_row_number > 500 - new_csv_rows.length) {
      add_row_number = Math.min(add_row_number, 500 - new_csv_rows.length);
      AlertDialog.show(`CSV定義は最大で500行までの為、${500 - new_csv_rows.length}行のみ追加しました`);
    }
    // 指定分だけ行を追加する。
    for (let i = 0; i < add_row_number; i++) {
      new_csv_rows.push({
        header_name: '',
        json_path: '',
        cell_format: '',
        cell_format_args: '',
        statistic_method: 'Key',
        fill: 'False',
      });
    }
    params.setCsvRows(new_csv_rows);
  };

  // row_numberの数だけ上に行を追加する。(MAX500)
  const handleAddSelectedRowClick = (row_number = 1, selected_index: number[]) => {
    if (Number.isNaN(row_number)) {
      AlertDialog.show('行数を1~500の間で入力してください');
      return;
    }
    const new_csv_rows = [...params.bodies];
    if (new_csv_rows.length >= 500) {
      AlertDialog.show('すでにCSV定義は最大の500行に達しています。');
      return;
    }
    // MINは1
    let add_row_number = Math.max(1, row_number);
    // 500行が最大なので、その差分がMAX
    if (add_row_number > 500 - new_csv_rows.length) {
      add_row_number = Math.min(add_row_number, 500 - new_csv_rows.length);
      AlertDialog.show(`CSV定義は最大で500行までの為、${500 - new_csv_rows.length}行のみ追加しました`);
    }

    const createNewRow = () => ({
      header_name: '',
      json_path: '',
      cell_format: '',
      cell_format_args: '',
      statistic_method: 'Key',
      fill: 'False' as StringBoolean,
      how_statistic_method: 'Sum' as MetricStatisticMethod,
      accumulation: 'NONE' as AccumulationType,
      stack_id: '1',
    });

    // 各 selected_index に新しい行を追加する
    // インデックスを昇順にソート
    selected_index.sort((a, b) => a - b);
    const rows_to_add = add_row_number;

    let offset = 0;
    const new_selected_index = [];

    for (let idx = 0; idx < selected_index.length; idx++) {
      const index = selected_index[idx] + offset;
      if (new_csv_rows.length + rows_to_add <= 500) {
        const new_rows = Array.from({ length: rows_to_add }, createNewRow);
        new_csv_rows.splice(index, 0, ...new_rows);
        // 追加した行数分、オフセットを増加
        offset += rows_to_add;
        // 新しいインデックスを保存
        new_selected_index.push(index + rows_to_add);
      } else {
        AlertDialog.show(`追加する行数が500行を超えるため、${500 - new_csv_rows.length}行のみ追加しました`);
        const remaining_rows = 500 - new_csv_rows.length;
        if (remaining_rows > 0) {
          const new_rows = Array.from({ length: remaining_rows }, createNewRow);
          new_csv_rows.splice(index, 0, ...new_rows);
          // 新しいインデックスを保存
          new_selected_index.push(index + remaining_rows);
        }
        // 500行を超えたらループを終了
        break;
      }
    }

    params.setCsvRows(new_csv_rows);
    setSelectedIndexes(new_selected_index);
  };

  // CSV定義を[]にする関数
  const handleDeleteAllClick = () => {
    params.setCsvRows([]);
  };

  // CSV定義をリセットする際は確認ダイアログを表示する
  const handleResetClick = () => {
    ConfirmDialog.show(
      <div style={{ color: colors.red }}>
        [確認]入力しているCSV定義をリセットします。
        <br />
        本当によろしいですか?
      </div>,
      () => handleDeleteAllClick(),
      () => {},
      undefined,
    );
  };

  const handleDeleteClick = () => {
    let new_csv_rows = params.bodies.slice();
    if (selected_indexes.length <= 0) {
      AlertDialog.show('削除する行を選択してください');
      return;
    }
    new_csv_rows = new_csv_rows.filter((ncr, i) => !selected_indexes.includes(i));
    // if(new_csv_rows.length <= 0){
    //   AlertDialog.show('CSV定義は少なくとも1つ以上の入力が必要です');
    //   new_csv_rows = [{
    //     header_name: '',
    //     json_path: '',
    //     cell_format: '',
    //     cell_format_args: '',
    //     statistic_method: 'Key',
    //     fill: 'False',
    //   }]
    // }
    params.setCsvRows(new_csv_rows);
    // 選択されていたテーブルのindexは消去
    setSelectedIndexes([]);
  };

  // -- render part --
  const header = params.showStatistics
    ? ['ヘッダ名', 'JSONパス', '出力式', '引数', '集計', '複製']
    : ['ヘッダ名', 'JSONパス', '出力式', '引数', '複製'];
  return (
    <WholeArea>
      <TopArea>
        <Popover
          content={
            <QuestionPopoverWholeArea>
              当置換は以下のような形式のJSONにのみ適用できます。
              <br />
              <br />
              $.c0.LINE0_LR_****
              <br />
              ($.カメラID.LINE番号_LR_****)
              <br />
              <br />
              上記以外のJSONパスグループに対して置換を行うとウィジェットの表示が無効となります。
            </QuestionPopoverWholeArea>
          }
          isOpen={is_open_menu_popover}
          onClose={close}
        >
          <IconButton
            img_src={topmenu_icon_question}
            style={{ width: 25, height: 25, marginLeft: 10 }}
            handleClick={open}
          />
        </Popover>
        {camera_id.length !== 0 || line_no.length !== 0 ? (
          <>
            <InputComponent text='カメラID' style={{ width: '70px', marginRight: '5px' }}>
              <SelectBox
                onChange={(e) => setReplacementCameraId(e.currentTarget.value)}
                value={replacement_camera_id || ''}
                datas={camera_id.map((c) => ({ name: c, value: c }))}
                style={{ width: 70 }}
              />
            </InputComponent>
            <InputComponent text='LINE番号' style={{ width: '70px', marginRight: '5px' }}>
              <SelectBox
                onChange={(e) => setReplacementLineNo(e.currentTarget.value)}
                value={replacement_line_no || ''}
                datas={line_no.map((l) => ({ name: l, value: l }))}
                style={{ width: 70 }}
              />
            </InputComponent>
            <RoundedButton
              disabled={!selected_indexes.length || (!replacement_line_no && !replacement_camera_id)}
              onClick={() => handleClickReprace()}
              text='置換'
              style={{
                width: styles.super_small_button_width,
                height: styles.super_small_button_height,
                marginRight: styles.interval_narrow_margin,
              }}
            />
          </>
        ) : (
          <Spinner size={20} />
        )}
      </TopArea>
      {/* テーブル */}
      <TableArea {...params.table_area_props}>
        <Table>
          <Thead>
            <tr>
              {/* テーブルヘッダー */}
              <Th style={{ width: 35 }}></Th>
              <Th style={{ width: 35 }}></Th>
              <Th style={{ width: 35 }}>
                <CheckBoxArea>
                  <CheckBox checked={checkHeaderChecked()} onClick={handleHeadCheckClick} />
                </CheckBoxArea>
              </Th>
              {header.map((h, i) => (
                <Th
                  key={i}
                  style={{
                    width: `calc((100% -35px)/ ${header.length})`,
                    paddingLeft: styles.interval_narrow_margin,
                  }}
                >
                  <ThChildrensArea>{h}</ThChildrensArea>
                </Th>
              ))}
            </tr>
          </Thead>

          {/* テーブルボディー */}
          <Tbody>
            {params.bodies.map((body, index1) => {
              return (
                <Tr key={index1}>
                  {/* テーブルボディーのチェックボックス */}
                  <Td>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <UpIcon onClick={() => handleUpClick(index1)} />
                      <DownIcon onClick={() => handleDownClick(index1)} />
                    </div>
                  </Td>
                  <Td>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>{index1 + 1}</div>
                  </Td>
                  <Td
                    key={`checkbox_${index1}`}
                    style={{ width: 35 }}
                    last_row={index1 + 1 === params.bodies.length ? true : false}
                  >
                    <CheckBoxArea>
                      <CheckBox
                        checked={selected_indexes.includes(index1)}
                        onClick={() => handleCheckClick(index1)}
                        data-testid={`${index1}-csv-checkbox`}
                      />
                    </CheckBoxArea>
                  </Td>
                  <Td key={`header_name_${index1}`} last_row={index1 + 1 === params.bodies.length ? true : false}>
                    <InputBox
                      data-testid={`${index1}-header_name`}
                      value={body.header_name}
                      onChange={(e) => handleCsvRowsChangeClick(e.currentTarget.value, index1, 'header_name')}
                    />
                  </Td>
                  <Td key={`json_path_${index1}`} last_row={index1 + 1 === params.bodies.length ? true : false}>
                    <JsonpathInput
                      value={body.json_path}
                      onJsonpathChanged={(item: string) => handleCsvRowsChangeClick(item, index1, 'json_path')}
                      placeholder='$から始めてください'
                      stream_id={params.stream_id}
                      data-testid={`${index1}-json_path`}
                    />
                  </Td>
                  <Td key={`cell_format_${index1}`} last_row={index1 + 1 === params.bodies.length ? true : false}>
                    <SelectBox
                      data-testid={`${index1}-cell_format`}
                      value={body.cell_format}
                      datas={cell_formats}
                      onChange={(e) => handleCsvRowsChangeClick(e.currentTarget.value, index1, 'cell_format')}
                      long
                    />
                  </Td>
                  <Td key={`cell_format_args_${index1}`} last_row={index1 + 1 === params.bodies.length ? true : false}>
                    <InputBox
                      data-testid={`${index1}-cell_format_args`}
                      value={body.cell_format_args}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleCsvRowsChangeClick(e.currentTarget.value, index1, 'cell_format_args')
                      }
                      placeholder='出力式がSUBSTRINGの時のみ入力可'
                      disabled={params.bodies[index1].cell_format !== 'SUBSTRING'}
                    />
                  </Td>
                  {params.showStatistics && (
                    <Td
                      key={`statistic_method_${index1}`}
                      last_row={index1 + 1 === params.bodies.length ? true : false}
                    >
                      <SelectBox
                        onChange={(e) => handleCsvRowsChangeClick(e.currentTarget.value, index1, 'statistic_method')}
                        value={body.statistic_method || 'Key'}
                        default_text='null'
                        data-testid={`${index1}-statistic_method`}
                        datas={[
                          { name: 'キー', value: 'Key' },
                          { name: '最初の値', value: 'FirstValue' },
                          { name: '最後の値', value: 'LastValue' },
                          { name: '最大値', value: 'Maximum' },
                          { name: '最小値', value: 'Minimum' },
                          { name: '合計値', value: 'Sum' },
                          { name: '平均値', value: 'Average' },
                          { name: '値の数', value: 'Count' },
                          { name: '最頻値', value: 'Mode' },
                        ]}
                      />
                    </Td>
                  )}
                  <Td key={`fill_${index1}`} last_row={index1 + 1 === params.bodies.length ? true : false}>
                    <SelectBox
                      value={body.fill}
                      data-testid={`${index1}-fill`}
                      datas={[
                        {
                          name: 'True',
                          value: 'True',
                        },
                        {
                          name: 'False',
                          value: 'False',
                        },
                      ]}
                      onChange={(e) => handleCsvRowsChangeClick(e.currentTarget.value, index1, 'fill')}
                      long
                    />
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableArea>

      {/* フッター */}
      <FooterArea>
        <FlexCenterDiv align_center={true} style={{ marginRight: styles.interval_margin }}>
          <InputNumberBox value={add_row_number} onChange={onChangeAddRowNumber} style={{ width: 75 }} />
          行
          <RoundedButton
            text='追加'
            onClick={() => handleAddClick(add_row_number)}
            small={true}
            is_margin_right={true}
            style={{
              marginLeft: styles.interval_x_narrow_margin,
            }}
          />
          {selected_indexes.length > 0 && (
            <RoundedButton
              text='上に追加'
              onClick={() => handleAddSelectedRowClick(add_row_number, selected_indexes)}
              small={true}
              is_margin_right={true}
              style={{
                marginLeft: styles.interval_x_narrow_margin,
              }}
            />
          )}
        </FlexCenterDiv>
        <RoundedButton text='自動定義' onClick={handleAutoSettingCsvClick} small={true} is_margin_right={true} />
        <RoundedButton text='削除' onClick={handleDeleteClick} small={true} is_margin_right={true} is_white />
        <RoundedButton text='リセット' onClick={handleResetClick} small={true} is_white />
      </FooterArea>
    </WholeArea>
  );
};

// -- styled components --

const WholeArea = styled.div`
  width: 100%;
  height: 100%;
  display: block;
`;

const TableArea = styled.div`
  max-width: 100%;
  width: 100%;
  height: calc(100% - 25px - 25px - ${styles.interval_narrow_margin} * 2);
  overflow-y: scroll;
  border-radius: ${styles.table_radius_px};
`;

const FooterArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 25px;
  margin-top: ${styles.interval_narrow_margin};
`;

const ThChildrensArea = styled.div`
  display: flex;
  align-items: center;
`;

const TopArea = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 2px;
`;

const QuestionPopoverWholeArea = styled(PopoverWholeArea)`
  width: 530px;
  height: 160px;
`;

// -- finally export part --

export default CSVTable;
